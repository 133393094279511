.App {
  margin: 0;
  width: 100%;
  height: 100vh;
  background-color: #01ff74;
  overflow-x: hidden;
}

.right{
  position: fixed;
  width: 50%;
  top: 0;
  right: 0;
  font-family: Century Gothic, CenturyGothic, AppleGothic, sans-serif;
  height: 100vh;
  line-height: 100vh;
  font-size: 70vh;
  text-align: center;
  font-weight: 900;
}

.left{
  position: fixed;
  width: 50%;
  top: 0;
  left: 0;
  font-family: Century Gothic, CenturyGothic, AppleGothic, sans-serif;
  height: 100vh;
  font-size: 70vh;
  text-align: left;
}

.left .logo{
  position: fixed;
  top: 8vh;
  left: 5%;
  width: 160px;
}

.left .blurb{
  position: fixed;
  width: 45%;
  bottom: 20vh;
  left: 5%;
}

.left .blurb .subtitle{
  font-size: 14px;
  font-weight: 400;
  text-transform: uppercase;
  text-align: left;
}

.left .blurb .title{
  font-size: 20px;
  font-weight: 700;
  text-align: left;
}

.left .blurb .contact{
  font-size: 14px;
  font-weight: 700;
  text-align: left;
  margin-top: 20px;
}

@media screen and (max-width: 1000px) {
  .left{
    width: 100% !important;
    z-index: 2;
  }

  .left .blurb{
    width: 75%;
  }

  .right{
    width: 90% !important;
    right: 0 !important;
    z-index: 0;
    color: #03e468;
  }
}